import React from 'react';
import { CssBaseline, Grid, Container } from '@material-ui/core';

import logo from './logo.png';
import './App.css';

const App: React.FC = () => {
  return (
    <React.Fragment>
      <CssBaseline />

      <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <p className="App-services">Web &#8226; Mobile</p>
      </header>

      <footer>
        <Container>
          <Grid container justify="space-around" alignItems="flex-end">
            <Grid item xs={12} sm={12} md={5}>
              <ul>
                <li>WEBIDIA Ltd</li>
                <li>The Workstation</li>
                <li>15 Paternoster Row</li>
                <li>Sheffield</li>
                <li>S1 2BX</li>
                <li><a className="App-link" href="mailto:info@webidia.com">info@webidia.com</a></li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md={7}>
              <ul>
                <li className="App-companyNo">WEBIDIA Ltd is a company registered England and Wales (Company No. 09250108)</li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </footer>
      </div>
    </React.Fragment>
      
  );
}

export default App;
